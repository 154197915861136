/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

// const iconName = ['fb', 'ig', 'in', 'google', 'zalo']
const iconName = [
  { icon: 'fb', linkIcon: 'https://www.facebook.com/blueskytech.link' },

  { icon: 'zalo', linkIcon: 'https://zalo.me/3564384125545349191' },
  { icon: 'youtube', linkIcon: 'https://www.youtube.com/channel/UCkPM0kjfpH8oGteDiWVJzBg' },
]
// {icon: 'ig', linkIcon: 'https://www.facebook.com/blueskytech.link'},
// {icon: 'in', linkIcon: 'https://www.facebook.com/blueskytech.link'},
// {icon: 'google', linkIcon: 'https://www.facebook.com/blueskytech.link'},

const linkAboutUs = [
  { title: 'MENU.ABOUTUS', path: 'about' },
  { title: 'MENU.SERVICES', path: 'services' },
  { title: 'MENU.PROJECTS', path: 'projects' },
  { title: 'MENU.CAREERS', path: 'careers' },
]

const inforCompany = [
  { title: 'FOOTER.TITLECODE', content: 'FOOTER.COMPANYCODE' },

  {
    title: 'FOOTER.TITLEADDRESS',
    content: 'FOOTER.COMPANYADDRESS',
  },
]

const contactCompany = [
  { title: 'AUTH.INPUT.EMAIL', content: 'truhuynh@blueskytech.vn' },
  { title: 'FOOTER.TITLEPHONE', content: '0932 801 527' },
]

const Footer: FC = () => {
  const intl = useIntl()
  return (
    // <div className='footer py-4 d-flex flex-lg-column bg-gray-100 pt-5 pb-5 mt-5' id='kt_footer'>
    <div
      className='footer bg-gray-100'
      id='kt_footer'
      style={{ padding: '3rem 0 2rem', marginTop: '2rem' }}
    >
      {/* begin::Container */}
      {/* end::Container */}
      {/* <div className="p-footer">
        <h1> {intl.formatMessage({ id: 'GENERAL.GETINTOUCH' })}</h1>
        <a href="mailto:truhuynh@blueskytech.vn">truhuynh@blueskytech.vn</a>
        <div className="p-footer_contentIcon">
          {iconName.map((cate, idx) => (
            <span key={idx} className={`p-footer_icon p-footer_icon-${cate}`}></span>
          ))}
        </div>
        <div className="p-footer_pic">
          <div className="p-footer_button">
            <a className="p-footer_button_explore">{intl.formatMessage({ id: 'GENERAL.EXPLORER_OUR' })}</a>
            <a className="p-footer_button_contact">{intl.formatMessage({ id: 'GENERAL.CONTACTUS' })}</a>
          </div>
        </div>
        <a className="p-footer_copyright" >&copy; Copyright BlueSkyTech 2022. All rights reserved </a>
      </div> */}
      <div className='container pt-5'>
        <div className='row'>
          <div className='col-md-5 mb-5'>
            <div className=''>
              <img
                alt='Logo'
                src='/media/logos/Hori_Logo.png'
                className='logo-sticky h-45px mb-4'
              ></img>
              <h2 className='fs-3 fw-bolder text-uppercase'>
                {intl.formatMessage({ id: 'FOOTER.COMPANYNAME' })}
              </h2>

              {inforCompany.map((item, index) => {
                return (
                  <div className='mb-5 mt-5' key={index}>
                    <p className='fs-4 text-gray-800 w-75'>
                      <span className='fw-bold text-gray-900 text-capitalize'>
                        {intl.formatMessage({ id: item.title })}:{' '}
                      </span>
                      {intl.formatMessage({ id: item.content })}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='col-md-3 mb-5'>
            <h2 className='fs-2 text-gray-800 mb-5'>{intl.formatMessage({ id: 'MENU.ABOUTUS' })}</h2>
            <div className='mb-5'>
              <div className='my-2'>
                {linkAboutUs.map((item, index) => {
                  return (
                    <div className='d-flex align-items-center mb-3' key={index}>
                      <span className='bullet bullet-dot bg-info me-3 h-5px w-5px'></span>
                      <div className='fw-bold fs-4'>
                        <Link to={item.path} className='text-capitalize'>
                          {intl.formatMessage({ id: item.title })}
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='mb-5'>
              <h2 className='fs-2 text-gray-800 mb-5'>
                {' '}
                {intl.formatMessage({ id: 'GENERAL.GETINTOUCH' })}
              </h2>

              {contactCompany.map((item, idx) => {
                return (
                  <div className='fs-4 text-gray-800 mb-2' key={idx}>
                    <span className='fw-bold text-gray-900 text-capitalize'>
                      {' '}
                      {intl.formatMessage({ id: item.title })}:
                    </span>{' '}
                    {item.content}
                  </div>
                )
              })}

              <div className='mt-5'>
                {/* <span key={idx} className={`p-footer_icon p-footer_icon-${cate}`}></span> */}
                {iconName.map((cate, idx) => {
                  return (
                    <a href={cate.linkIcon} target='blank' key={idx}>
                      <i className={`p-footer_icon p-footer_icon-${cate.icon}`}></i>
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='text-center'>
          <span className='p-footer_copyright fs-5'>
            &copy; Copyright BlueSkyTech {new Date().getFullYear()}. All rights reserved{' '}
          </span>
        </div>
      </div>
    </div>
  )
}

export { Footer }

import React, {FC} from 'react'
import './index.scss'

interface Article {
  img?: string
  date?: string
  type?: string
  title?: string
  height?: number
}

const Item: FC<Article> = (props: Article) => {
  return (
    <div className='item' style={{height: props.height}}>
      <div className='content'>
        <div className='time'>
          {props.type} • {props.date}
        </div>
        <div className='title'>{props.title}</div>
      </div>
      <div className='background' id='background'>
        <img alt='projects-of-blueskytech' src={props.img} />
      </div>
    </div>
  )
}

export {Item}

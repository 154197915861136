/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {BannerWrapper} from './components/Banner/Banner'
import ServicesWrapper from '../services/Services'
import {ProjectsWrapper} from './components/Projects/Project'
import {AboutUsWrapper} from './components/AboutUs/AboutUs'
import {ClientsWrapper} from './components/Clients/Clients'
import {InsightsWrapper} from './components/Insights/Insights'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <BannerWrapper />
    {/* end::Row */}

    {/* begin::Row */}
    <ServicesWrapper isHome />
    {/* end::Row */}

    {/* begin::Row */}
    <ProjectsWrapper />
    {/* end::Row */}

    {/* begin::Row */}
    <AboutUsWrapper />
    {/* end::Row */}

    {/* begin::Row */}
    <ClientsWrapper />
    {/* end::Row */}

    {/* begin::Row */}
    <InsightsWrapper />
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}

import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import { Routes } from './routing/Routes'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Helmet>
        <link rel="canonical" href={process.env.REACT_APP_OFFICAL_URL} />
        <title>Technology for a better life | BlueSkyTech</title>
        <meta name="description"
          content="BlueSkyTech is a company specializing in serving and building web applications, software on mobile and web platforms. In addition, we also offer digital transformation solutions for small and medium businesses." />
        <meta name="keywords" content="BlueSky, BlueSkyTech, Outsourcing, Freelancer, Software" />

        <meta property="og:title" content="Technology for a better life | BlueSkyTech" />
        <meta property="og:description"
          content="BlueSkyTech is a company specializing in serving and building web applications, software on mobile and web platforms. In addition, we also offer digital transformation solutions for small and medium businesses." />
        <meta property="og:image" content="/media/images/common/thumbnail-seo-2.png" />
        <meta property="og:url" content={process.env.REACT_APP_OFFICAL_URL} />
        <meta property="og:site_name" content="BlueSky Technology Vietnam" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="vi_VN" />
      </Helmet>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }

export interface IProject {
    id: number;
    name: string;
    alias: string;
    keyword: string;
    shortDescription: string;
    type: string;
    logo: string;
    thumbnail: string;
    background: string;
    images: string[];
    originId: number;
    languageCode: string;
};

export const projects = [
    {
        id: 1,
        name: "123Bay Vietnam",
        alias: "123bay-vietnam",
        keyword: "An agent specializing in providing cheap and reputable flight booking services | 123Bay",
        shortDescription: "123Bay is proud to be one of the agents providing the service of booking cheap airline tickets online and providing the most dedicated customer support.",
        type: "Flight booking service",
        logo: "/media/images/logo/logo-123bay.vn.png",
        thumbnail: "/media/images/projects/thumb/123bay-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/123bay.png", "/media/images/projects/123bay-contact.png"],
        originId: 2,
        languageCode: "en"
    },
    {
        id: 2,
        name: "123Bay Việt Nam",
        alias: "123bay-vietnam",
        keyword: "Đại lý chuyên cung cấp dịch vụ đặt vé máy bay giá rẻ, uy tín | 123Bay",
        shortDescription: "123Bay tự hào là một trong những đại lý cung cấp dịch vụ đặt mua vé máy bay giá rẻ trực tuyến và hỗ trợ khách hàng tận tình nhất.",
        type: "Dịch vụ đặt vé máy bay",
        logo: "/media/images/logo/logo-123bay.vn.png",
        thumbnail: "/media/images/projects/thumb/123bay-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/123bay.png", "/media/images/projects/123bay-contact.png"],
        originId: 1,
        languageCode: "vi"
    },
    {
        id: 3,
        name: "Property24 Vietnam",
        alias: "property24-vietnam",
        keyword: "Buy, sell, rent apartments, offices | Real estate information channel | Property24",
        shortDescription: "We develop a website based on modern technology platforms, in order to connect all users such as investors, consultants, landlords, brokers to access information sources. about buying, selling, renting real estate and trends in the market quickly, simply and effectively.",
        type: "Real Estate Services",
        logo: "/media/images/logo/logo-property24.vn.svg",
        thumbnail: "/media/images/projects/thumb/property24-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/property24.jpg", "/media/images/projects/property24-admin.png"],
        originId: 4,
        languageCode: "en"
    },
    {
        id: 4,
        name: "Property24 Việt Nam",
        alias: "property24-vietnam",
        keyword: "Mua bán, cho thuê căn hộ, văn phòng | Kênh thông tin bất động sản | Property24",
        shortDescription: "Chúng tôi phát triển trang web dựa trên những nền tảng công nghệ hiện đại, nhằm kết nối tất cả người dùng như nhà đầu tư, tư vấn, chủ nhà, môi giới tiếp cận nguồn thông tin về mua bán, cho thuê bất động sản và xu hướng về thị trường một cách nhanh chóng, đơn giản và hiệu quả.",
        type: "Dịch vụ bất động sản",
        logo: "/media/images/logo/logo-property24.vn.svg",
        thumbnail: "/media/images/projects/thumb/property24-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/property24.jpg", "/media/images/projects/property24-admin.png"],
        originId: 3,
        languageCode: "vi"
    },
    {
        id: 5,
        name: "Bidding for drugs",
        alias: "bidding-for-drugs",
        keyword: "Bidding for drugs, medical supplies, chemicals and biologicals for hospital testing",
        shortDescription: "Military Hospital 121 is one of the hospitals that are using a separate on-demand service to bid for drugs, medical supplies, chemicals and biologicals for testing for Military Region 9.",
        type: "Bidding Service",
        logo: "/media/images/logo/logo-benhvienquany121.vn.png",
        thumbnail: "/media/images/projects/thumb/dau-thau-truc-tuyen-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/dau-thau-truc-tuyen.png", "/media/images/projects/dau-thau-truc-tuyen-pm-khach-hang.png", "/media/images/projects/dau-thau-truc-tuyen-pm-quan-ly.png"],
        originId: 6,
        languageCode: "en"
    },
    {
        id: 6,
        name: "Đấu thầu thuốc, vật tư y tế",
        alias: "dau-thau-thuoc-vat-tu-y-te",
        keyword: "Đấu thầu thuốc, vật tư y tế, hoá chất sinh phẩm xét nghiệm cho bệnh viện",
        shortDescription: "Bệnh viện quân y 121 là một trong những bệnh viện đang sử dụng dịch vụ theo yêu cầu riêng biệt dùng để đấu thầu thuốc, vật tư y tế, hoá chất sinh phẩm xét nghiệm cho quân khu 9.",
        type: "Dịch vụ đấu thầu",
        logo: "/media/images/logo/logo-benhvienquany121.vn.png",
        thumbnail: "/media/images/projects/thumb/dau-thau-truc-tuyen-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/dau-thau-truc-tuyen.png", "/media/images/projects/dau-thau-truc-tuyen-pm-khach-hang.png", "/media/images/projects/dau-thau-truc-tuyen-pm-quan-ly.png"],
        originId: 5,
        languageCode: "vi"
    },
    {
        id: 7,
        name: "Viet Chuyen SG Technology",
        alias: "viet-chuyen-sg-technology",
        keyword: "Providing information technology system infrastructure products and solutions",
        shortDescription: "Viet Chuyen SG is a professional supplier in Vietnam of IT system infrastructure product solutions including: CCTV equipment, uninterruptible power equipment (UPS), lightning protection equipment, transmission equipment telecommunications, CCTV systems, Structured cabling systems, network equipment for smart buildings, Data centers and large systems.",
        type: "IT equipment supply service",
        logo: "/media/images/logo/logo-vietchuyen.vn.png",
        thumbnail: "/media/images/projects/thumb/viet-chuyen-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/viet-chuyen.png"],
        originId: 8,
        languageCode: "en"
    },
    {
        id: 8,
        name: "Công nghệ Việt Chuyên SG",
        alias: "cong-nghe-viet-chuyen-sg",
        keyword: "Cung cấp các giải pháp và sản phẩm cơ sở hạ tầng hệ thống công nghệ thông tin",
        shortDescription: "Việt Chuyên SG là nhà cung cấp chuyên nghiệp tại Việt Nam về các giải pháp sản phẩm cơ sở hạ tầng hệ thống CNTT bao gồm: thiết bị CCTV, thiết bị nguồn liên tục (UPS), thiết bị chống sét, thiết bị truyền dẫn viễn thông, hệ thống CCTV, hệ thống Cáp cấu trúc, thiết bị mạng cho các cao ốc thông minh, Trung tâm dữ liệu và các hệ thống lớn.",
        type: "Dịch vụ cung cấp thiết bị CNTT",
        logo: "/media/images/logo/logo-vietchuyen.vn.png",
        thumbnail: "/media/images/projects/thumb/viet-chuyen-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/viet-chuyen.png"],
        originId: 7,
        languageCode: "vi"
    },
    {
        id: 9,
        name: "People's Procuratorate",
        alias: "peoples-procuratorate",
        keyword: "Display information, news, manage receipt of complaints, denunciations, suggestions, recommendations, reflections, requests.",
        shortDescription: "We build a web system to display information, news, manage and receive complaints, denunciations, suggestions, recommendations, reflections, requests.",
        type: "Application Web Service",
        logo: "/media/images/logo/logo-vksndcm.gov.vn-removebg-preview.png",
        thumbnail: "/media/images/projects/thumb/vien-kiem-sat-nhan-dan-ca-mau-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/vien-kiem-sat-nhan-dan-ca-mau.png"],
        originId: 10,
        languageCode: "en"
    },
    {
        id: 10,
        name: "Viện Kiểm Sát Nhân Dân",
        alias: "vien-kiem-sat-nhan-dan",
        keyword: "Hiển thị thông tin, tin tức, quản lý tiếp nhận đơn khiếu nại, tố cáo, đề nghị, kiến nghị, phản ánh, yêu cầu.",
        shortDescription: "Chúng tôi xây dựng hệ thống web hiển thị thông tin, tin tức, quản lý tiếp nhận đơn khiếu nại, tố cáo, đề nghị, kiến nghị, phản ánh, yêu cầu.",
        type: "Dịch vụ web ứng dụng",
        logo: "/media/images/logo/logo-vksndcm.gov.vn-removebg-preview.png",
        thumbnail: "/media/images/projects/thumb/vien-kiem-sat-nhan-dan-ca-mau-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/vien-kiem-sat-nhan-dan-ca-mau.png"],
        originId: 9,
        languageCode: "vi"
    },
    {
        id: 11,
        name: "Pham Ngoc Thach College",
        alias: "pham-ngoc-thach-college",
        keyword: "Manage information about timetable, exam schedule, test scores, study results, manage library of images, videos, study materials.",
        shortDescription: "We build a web system to manage information about timetables, exam schedules, test scores, study results, image library management, videos, learning materials.",
        type: "Application Web Service",
        logo: "/media/images/logo/logo-phamngocthachct.edu.vn.png",
        thumbnail: "/media/images/projects/thumb/pham-ngoc-thach-can-tho-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/pham-ngoc-thach-can-tho.png", "/media/images/projects/pham-ngoc-thach-can-tho-admin.png"],
        originId: 12,
        languageCode: "en"
    },
    {
        id: 12,
        name: "Cao Đẳng Phạm Ngọc Thạch",
        alias: "cao-dang-pham-ngoc-thach",
        keyword: "Quản lý thông tin về thời khoá biểu, lịch thi, điểm thi, kết quả học tập, quản lý thư viện hình ảnh, video, tài liệu học tập.",
        shortDescription: "Chúng tôi xây dựng hệ thống web quản lý thông tin về thời khoá biểu, lịch thi, điểm thi, kết quả học tập, quản lý thư viện hình ảnh, video, tài liệu học tập.",
        type: "Dịch vụ web ứng dụng",
        logo: "/media/images/logo/logo-phamngocthachct.edu.vn.png",
        thumbnail: "/media/images/projects/thumb/pham-ngoc-thach-can-tho-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/pham-ngoc-thach-can-tho.png", "/media/images/projects/pham-ngoc-thach-can-tho-admin.png"],
        originId: 11,
        languageCode: "vi"
    },
    {
        id: 13,
        name: "Electronic invoice solution",
        alias: "electronic-invoice-solution",
        keyword: "Manage invoice templates, monthly, quarterly and yearly statistical reports",
        shortDescription: "The electronic invoice solution is one of the solutions to be processed quickly, conveniently and accurately when it has been linked and coordinated with software units that have invoicing information such as: ERP, Software restaurant software - sales, Hospital, accounting software,...",
        type: "Electronic invoice",
        logo: "/media/images/logo/logo-minvoice.png",
        thumbnail: "/media/images/projects/thumb/minvoice-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/hoa-don-dien-tu-dashboard.png", "/media/images/projects/hoa-don-dien-tu-login.png", "/media/images/projects/hoa-don-dien-tu-vat-01.png"],
        originId: 14,
        languageCode: "en"
    },
    {
        id: 14,
        name: "Giải pháp hoá đơn điện tử",
        alias: "giai-phap-hoa-don-dien-tu",
        keyword: "Quản lý các mẫu hoá đơn, báo cáo thống kê theo tháng, quý, năm",
        shortDescription: "Giải pháp hóa đơn điện tử là một trong những giải pháp được xử lý nhanh chóng, thuận tiện và chính xác khi đã liên kết và phối hợp với các đơn vị phần mềm có thông tin xuất hóa đơn như: ERP, Phần mềm nhà hàng – bán hàng, Bệnh viện, Phần mềm kế toán,...",
        type: "Hóa đơn điện tử",
        logo: "/media/images/logo/logo-minvoice.png",
        thumbnail: "/media/images/projects/thumb/minvoice-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/hoa-don-dien-tu-dashboard.png", "/media/images/projects/hoa-don-dien-tu-login.png", "/media/images/projects/hoa-don-dien-tu-vat-01.png"],
        originId: 13,
        languageCode: "vi"
    },
    {
        id: 15,
        name: "Electronic Contract Solution",
        alias: "electronic-contract-solution",
        keyword: "Manage contract packages, transactions, statistical reports",
        shortDescription: "The electronic contract solution is one of the solutions that can be implemented quickly, conveniently and securely when partners need to sign contracts with each other",
        type: "Electronic Contract",
        logo: "/media/images/logo/logo-mcontract.png",
        thumbnail: "/media/images/projects/thumb/hop-dong-dien-tu-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/hop-dong-dien-tu-ds-donvi.png","/media/images/projects/hop-dong-dien-tu-ds-baocao.png","/media/images/projects/hop-dong-dien-tu-ds-giaodich.png"],
        originId: 16,
        languageCode: "en"
    },
    {
        id: 16,
        name: "Giải pháp hợp đồng điện tử",
        alias: "giai-phap-hop-dong-dien-tu",
        keyword: "Quản lý các gói hợp đồng, giao dịch, báo cáo thống kê",
        shortDescription: "Giải pháp hợp đồng điện tử là một trong những giải pháp được thực hiện nhanh chóng, thuận tiện và bảo mật khi các đối tác cần ký kết hợp đồng với nhau",
        type: "Hợp đồng điện tử",
        logo: "/media/images/logo/logo-mcontract.png",
        thumbnail: "/media/images/projects/thumb/hop-dong-dien-tu-thumb.png",
        background: "/media/images/projects/property24-bg.png",
        images: ["/media/images/projects/hop-dong-dien-tu-ds-donvi.png","/media/images/projects/hop-dong-dien-tu-ds-baocao.png","/media/images/projects/hop-dong-dien-tu-ds-giaodich.png"],
        originId: 15,
        languageCode: "vi"
    }
];

export default projects;

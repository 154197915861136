import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
// import'./slick.js';
import 'slick-carousel/slick/slick.css'
import './clients.scss'

const ClientsWrapper: FC = () => {
  const intl = useIntl()
  var settings = {
    dots: true,
    infinite: false,
    rows: 2,
    slidesToShow: 4,
    slidesToScroll: 4,
    // initialSlide: 4,
    arrows: false,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 3,
          slidesToclick: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 3,
          initialSlide: 2,
          Swipe: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1.2,
          dots: false,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1.2,
          dots: false,
        },
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1.2,
        },
      },
    ],
  }
  return (
    <>
      {/* We are trusted by... */}
      <div className='project-trusted'>
        <div className='container'>
          <h2>{intl.formatMessage({ id: 'CLIENTS.TRUSTED' })}</h2>
          <div className='col-xxl-7'>
            <p>
              {intl.formatMessage({ id: 'CLIENTS.TRUSTED.CONTENT' })}
            </p>
          </div>
          <div className='row padding-project-trusted'>
            <Slider {...settings}>
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-123bay.vn.png" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-property24.vn.svg" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>  
              {/*  */}
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-phamngocthachct.edu.vn.png" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-123bay.vn.png" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-property24.vn.svg" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>
              
              {/*  */}
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-phamngocthachct.edu.vn.png" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-123bay.vn.png" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-property24.vn.svg" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>
             
            
              {/*  */}
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-phamngocthachct.edu.vn.png" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-123bay.vn.png" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-property24.vn.svg" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>              
              
              {/*  */}
              <div className='col-xxl-3'>
                <button className='project-trusted-button'>
                  <img src="media/images/logo/logo-phamngocthachct.edu.vn.png" alt="" />
                </button>
                <div className='trusted-hover-buttom fw-bolder d-flex'>
                  <a href='./project-detail'>{intl.formatMessage({ id: 'CLIENTS.TRUSTED.SHOWCASE' })}</a>
                  <div className='line-width-icon-3 '>
                    <i className='arrow right-icon-3'></i>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  )
}

export { ClientsWrapper }


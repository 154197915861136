import {FC} from 'react'
import {Container, Row, Col, Card} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import ArrowNavigation from '../../common/components/arrow-navigation'
import './services.scss'

type ServicesProps = {
  isHome?: boolean
}

const ServicesWrapper: FC<ServicesProps> = ({isHome = false}) => {
  const intl = useIntl()

  return (
    <>
      <Container className='services-home-container' style={{display: isHome ? 'block' : 'none'}}>
        <div className='title fw-bold'>{intl.formatMessage({id: 'MENU.SERVICES'})}</div>
        <Row>
          <Col className='sub-title' sm={6}>
            we’re all about co-creation. From ideation through launch, we work with our customers
            every step of the way.
          </Col>
          <Col sm={6}>
            <div className='d-none d-sm-flex justify-content-end'>
              <ArrowNavigation
                href='/services'
                title={intl.formatMessage({id: 'SOLUTIONS.EXPLORE'})}
                isRight
                isCircle={true}
                color='black'
              />
            </div>
            <div className='d-flex d-sm-none mb-5'>
              <ArrowNavigation
                href='/services'
                title={intl.formatMessage({id: 'SOLUTIONS.EXPLORE'})}
                isRight
                color='black'
              />
            </div>
          </Col>
        </Row>
      </Container>

      <div className='services-container' style={{height: isHome ? 'auto' : ''}}>
        <div className='overlay-bg'></div>
        <Container className='services-content'>
          <div className='title' style={{display: isHome ? 'none' : 'block'}}>
            {intl.formatMessage({id: 'MENU.SERVICES'})}
          </div>
          <Row className={`content ${isHome ? 'home-padding' : ''}`}>
            <Col xs={12} md={4}>
              <Card>
                <Card.Title>
                  {intl.formatMessage({id: 'SOLUTIONS.TITLE_1.1ST_CHAR'})} <br />{' '}
                  {intl.formatMessage({id: 'SOLUTIONS.TITLE_1.2ND_CHAR'})}
                </Card.Title>
                <Card.Text>{intl.formatMessage({id: 'SOLUTIONS.SUB_TITLE_1'})}</Card.Text>
                <ArrowNavigation
                  href='/product-design'
                  title={intl.formatMessage({id: 'GENERAL.EXPLORER_OUR'})}
                  isRight
                  color='white'
                />
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Title>
                  {intl.formatMessage({id: 'SOLUTIONS.TITLE_2.1ST_CHAR'})} <br />{' '}
                  {intl.formatMessage({id: 'SOLUTIONS.TITLE_2.2ND_CHAR'})}
                </Card.Title>
                <Card.Text>{intl.formatMessage({id: 'SOLUTIONS.SUB_TITLE_2'})}</Card.Text>
                <ArrowNavigation
                  href='/solutions-software-development'
                  title={intl.formatMessage({id: 'GENERAL.EXPLORER_OUR'})}
                  isRight
                  color='white'
                />
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Title>
                  {intl.formatMessage({id: 'SOLUTIONS.TITLE_3.1ST_CHAR'})} <br />{' '}
                  {intl.formatMessage({id: 'SOLUTIONS.TITLE_3.2ND_CHAR'})}
                </Card.Title>
                <Card.Text>{intl.formatMessage({id: 'SOLUTIONS.SUB_TITLE_3'})}</Card.Text>
                <ArrowNavigation
                  href='/erp-development'
                  title={intl.formatMessage({id: 'GENERAL.EXPLORER_OUR'})}
                  isRight
                  color='white'
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ServicesWrapper

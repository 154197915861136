import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../helpers'
import { setLanguage, useLang } from '../../../i18n/Metronici18n'
//import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search } from '../../../partials'
//import { Languages } from '../../../partials/layout/header-menus/Languages'

// const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
//   toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
//   toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
//   toolbarButtonIconSizeClass = 'svg-icon-1'
const languages = [
  {
    lang: 'en',
    name: 'EN',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'vi',
    name: 'VI',
    flag: toAbsoluteUrl('/media/flags/vietnam.svg'),
  },
]

const Topbar: FC = () => {
  const intl = useIntl();
  const lang = useLang();
  const currentLanguage = languages.find((x) => x.lang === lang)
  const handleChange = (e: any) => {
    setLanguage(e.target.value);
  }
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* Search */}
        <a href='/contact' className="nav-input"><span>{intl.formatMessage({ id: "AUTH.GENERAL.CONTACT" })}</span></a>
        {/*  */}
        <div className="select">
          <select className="selected disabled border-0" onChange={handleChange} defaultValue={currentLanguage?.lang}>
            {languages.map((item, idx) => (
              <option key={idx}
                value={item.lang} style={{ height: "90px" }}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        {/*  */}


        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
     <Search />
      </div> */}
        {/* Activities */}
        {/* <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>
  
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_activities_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen032.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
    
        </div> */}
        {/* Quick links */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
  
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen025.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <QuickLinks />
        </div> */}

        {/* CHAT */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>

          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_drawer_chat_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com012.svg'
              className={toolbarButtonIconSizeClass}
            />

            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
          </div>

        </div> */}

        {/* NOTIFICATIONS */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen022.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <HeaderNotificationsMenu />

        </div> */}

        {/* begin::User */}
        {/* <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-25px w-25px rounded'
              src={toAbsoluteUrl('/media/avatars/150-25.jpg')}
              alt='metronic'
            />
          </div>
          <HeaderUserMenu />
    
        </div> */}
        {/* end::User */}
      </div>
    </div>
  )
}

export { Topbar }


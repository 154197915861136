/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { Logout } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { PrivateRoutes } from './PrivateRoutes'

const Routes: FC = () => {
  //const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  return (
    <Switch>
      {/* {!isAuthorized ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from='/auth' to='/' />
      )} */}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {/* {!isAuthorized ? (
        <Redirect to='/auth/login' />
      ) : ( */}
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      {/* )} */}
    </Switch>
  )
}

export { Routes }


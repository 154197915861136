import {FC} from 'react'
import './arrow.scss'

type ArrowProps = {
  title: string
  href: string
  color: string | 'white'
  isRight?: boolean | true
  isCircle?: boolean | false
}

const ArrowNavigation: FC<ArrowProps> = ({title, href, isRight, isCircle, color}) => {
  return (
    <div className='arrow-wrapper'>
      {/* Arrow left */}
      <div className={`arrow arrow--left arrow--${color}`} style={{display: isRight ? 'none' : ''}}>
        <span></span>
      </div>
      <a href={href} className={`explore explore-${isRight ? 'right' : 'left'} ${color}`}>
        {title}
      </a>
      {/* Arrow right */}
      <div
        className={`arrow arrow--right arrow--${color}`}
        style={{display: !isRight ? 'none' : ''}}
      >
        <span></span>
      </div>
      <div className={`circle ${color}`} style={{display: !isCircle ? 'none' : ''}}></div>
    </div>
  )
}

export default ArrowNavigation

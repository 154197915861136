import {FC, useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {IInsight, Insights} from '../../../../../assets/InsightData'
import {transferTitleToAlias} from '../../../../common/utilities'
import './insights.scss'
import {Item} from './items/index'

const InsightsWrapper: FC = () => {
  const intl = useIntl()
  const currentLang = intl.locale

  const [sizeLeft, setSizeLeft] = useState(150)
  const [sizeRight, setSizeRight] = useState(150)

  const [insightData, setInsightData] = useState<IInsight[]>([])
  const [highlightInsight, setHighlightInsight] = useState<IInsight>()
  const [normalInsight, setNormalInsight] = useState<IInsight[]>([])

  const dashboardInsigt =
    Insights.filter((x) => x.languageCode === currentLang)
      ?.sort((a, b) => (a.id > b.id ? 1 : -1))
      ?.slice(0, 5) || []

  useEffect(() => {
    if ((intl.locale || intl.locale !== '') && insightData.length > 0) {
      const ins = insightData.filter((x) => x.languageCode === intl.locale) || []
      if (ins.length > 0) {
        setHighlightInsight(ins.shift()) // expect an object
        setNormalInsight(ins) // rest items will be in array
      }
    }
  }, [intl.locale, insightData])

  useEffect(() => {
    setInsightData(dashboardInsigt)
    // only execute all the code below in client side
    if (typeof window !== undefined) {
      // Handler to call on window resize
      const handleResize = () => {
        if (window.innerWidth > 768) {
          setSizeLeft(600)
          setSizeRight(286)
        } else {
          setSizeLeft(200)
          setSizeRight(200)
        }
      }

      // Add event listener
      window.addEventListener('resize', handleResize)

      // Call handler right away so state gets updated with initial window size
      handleResize()

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className='insights'>
      <div className='container'>
        <div className='title'>Insights</div>
        <Row className='content'>
          <Col sm={12} lg={4} className='left'>
            {highlightInsight && (
              <Link
                to={`insights/${transferTitleToAlias(highlightInsight.title)}_${
                  highlightInsight.uniquePostKey
                }.html`}
              >
                <Item
                  img={highlightInsight.thumbnail}
                  date={highlightInsight.date}
                  height={sizeLeft}
                  title={highlightInsight.name}
                  type={highlightInsight.type}
                />
              </Link>
            )}
          </Col>
          <Col sm={12} lg={8} className='right '>
            <Row className='items'>
              {normalInsight.length > 0 &&
                normalInsight.map((item, index) => {
                  if (index < 2) {
                    return (
                      <Col
                        key={item.id}
                        sm={6}
                        lg={6}
                        className='col-6 col-mobile'
                        style={{paddingBottom: 'calc(1.5rem * .5)'}}
                      >
                        <Link
                          to={`insights/${transferTitleToAlias(item.title)}_${
                            item.uniquePostKey
                          }.html`}
                        >
                          <Item
                            img={item.thumbnail}
                            date={item.date}
                            height={sizeRight}
                            title={item.name}
                            type={item.type}
                          />
                        </Link>
                      </Col>
                    )
                  } else if (index >= 2 && index < 4) {
                    return (
                      <Col
                        key={item.id}
                        sm={6}
                        lg={6}
                        className='col-6 col-mobile'
                        style={{paddingTop: 'calc(1.5rem * .5)'}}
                      >
                        <Link
                          to={`insights/${transferTitleToAlias(item.title)}_${
                            item.uniquePostKey
                          }.html`}
                        >
                          <Item
                            img={item.thumbnail}
                            date={item.date}
                            height={sizeRight}
                            title={item.name}
                            type={item.type}
                          />
                        </Link>
                      </Col>
                    )
                  }
                  return false
                })}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export {InsightsWrapper}

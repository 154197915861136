import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import ProjectContent from '../../../projects/ProjectContent'
import YouTubePlayer from 'react-player/youtube'

const ProjectsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <div className='project-home'>
      <div className='project-home-text'>
        <div className='col-xxl-8 col-lg-9 col-sm-11 col-10 text-center project-auto'>
          <span className='position-relative'>
            <img src='media/images/home-page/Path.svg' alt='' className='w-100 position-absolute' />
            {intl.formatMessage({id: 'HOME.PROJECT.SAYING'})}
          </span>
        </div>
      </div>
      <div className='project-home-text'>
        <div className='col-xxl-8 col-lg-9 col-sm-11 col-10 project-auto'>
          <YouTubePlayer
            className='youtube-player'
            url={process.env.REACT_APP_YOUTUBE_URL}
            width='100%'
            // height='500px'
            controls={true}
          />
        </div>
      </div>
      <ProjectContent />
    </div>
  )
}

export {ProjectsWrapper}

import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { MenuComponent } from '../../_metronic/assets/ts/components'
import {
  ActivityDrawer, DrawerMessenger,
  ExploreMain, InviteUsers, Main, UpgradePlan
} from '../partials'
import { Content } from './components/Content'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { PageDataProvider } from './core'
import { MasterInit } from './MasterInit'

const MasterLayout: React.FC = ({ children }) => {
  //const { classes } = useLayout()

  const location = useLocation()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            {/* <Toolbar /> */}
            <div
              className={clsx(
                ' d-flex flex-column-fluid align-items-start',
                // classes.contentContainer.join(' ')
              )}
              id='kt_post'
            >
              {/* <AsideDefault /> */}
              <Content>{children}</Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <ExploreMain />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }


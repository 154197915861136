import React, {FC} from 'react'
import { useIntl } from 'react-intl'
import './aboutUs.scss'

const AboutUsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <div className='project-teams d-flex'>
        <div className='col-xxl-6'>
          <div className='d-flex flex-wrap'>
            {/* <div className='col-xxl-6 col-lg-6 col-sm-6 col-6  project-teams-hover-img'>
              <img
                src='assets/images/Home-page/img_07.jpg'
                className='w-100' alt="We also work as blended teams"
              />
            </div> */}
            <div className='col-xxl-6 col-lg-6 col-sm-6 col-6 project-teams-hover-img'>
              <img alt="projects-of-blueskytech"
                src='media/images/home-page/img_07_1.jpg'
                className='w-100 h-100 object-fit-img'
              />
            </div>
            <div className='col-xxl-6 col-lg-6 col-sm-6 col-6 project-teams-hover-img'>
              <img alt="projects-of-blueskytech"
                 src='media/images/home-page/img_07_2.jpg'
                className='w-100 h-100 object-fit-img'
              />
            </div>
            <div className='col-xxl-6 col-lg-6 col-sm-6 col-6 project-teams-hover-img'>
              <img alt="projects-of-blueskytech"
                     src='media/images/home-page/img_07_3.jpg'
                className='w-100 h-100 object-fit-img'
              />
            </div>
            <div className='col-xxl-6 col-lg-6 col-sm-6 col-6 project-teams-hover-img'>
              <img alt="projects-of-blueskytech"
               src='media/images/home-page/img_07_4.jpg'
                className='w-100 h-100 object-fit-img'
              />
            </div>
          </div>
        </div>
        {/* text */}
        <div className='col-xxl-6 color-text-right'>
          <h2 className='text-white'>{intl.formatMessage({ id: 'ABOUTUS.NAME' })}</h2>
          <div className='col-xl-10 project-teams-span-padding'>
            <span className='text-white'>
            {intl.formatMessage({ id: 'ABOUTUS.DESC' })}
            </span>
          </div>
          <div className='d-flex align-items-end'>
            <div>
              <a href='about' className='fs-6 text-start text-white '>
              {intl.formatMessage({ id: 'ABOUTUS.EXPLOREMORE' })}
              </a>
            </div>
            <div className='line-width-icon-2 justify-content-end'>
              <i className='arrow right-icon-2'></i>
            </div>
          </div>
        </div>
      </div> 
    </>
  )
}

export {AboutUsWrapper}

import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const AboutWrapper = lazy(() => import('../pages/about-us/About'))
  const InsightsWrapper = lazy(() => import('../pages/insights/index'))
  const ProjectsPageWrapper = lazy(() => import('../pages/projects/ProjectsPageWrapper'))
  const ProjectDetailPage = lazy(() => import('../pages/projects/ProjectDetailPage'))
  const ServicesWrapper = lazy(() => import('../pages/services/Services'))
  const ProductDesignServiceWrapper = lazy(() => import('../pages/services/ProductDesignService'))
  const SoftwareDevelopmentService = lazy(
    () => import('../pages/services/SoftwareDevelopmentService')
  )
  const ERPService = lazy(() => import('../pages/services/ERPService'))
  const CareersPageWrapper = lazy(() => import('../pages/careers/CareersPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const Contact = lazy(() => import('../pages/contact/contact'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/about' component={AboutWrapper} />
        <Route path='/insights' component={InsightsWrapper} />
        <Route path='/projects' component={ProjectsPageWrapper} />
        <Route path='/project-detail/:alias' component={ProjectDetailPage} />
        <Route path='/careers' component={CareersPageWrapper} />
        <Route path='/services' component={ServicesWrapper} />
        <Route path='/product-design' component={ProductDesignServiceWrapper} />
        <Route path='/solutions-software-development' component={SoftwareDevelopmentService} />
        <Route path='/erp-development' component={ERPService} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Route path='/contact' component={Contact} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect exact from='/index.html' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}

import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import projects, { IProject } from '../../../assets/ProjectsData'
import './ProjectContent.scss'


const ProjectContent: FC = () => {
  const intl = useIntl();
  let recordsProject: IProject[] = [];
  if (projects && projects.length > 0) {
    recordsProject = projects.filter(x => x.languageCode === intl.locale);
  }
  return (
    <div className='project-home'>
      <div className='container'>
        <div className='project-text'>
          <h1 className='fw-bold '>{intl.formatMessage({ id: 'MENU.PROJECTS' })}</h1>
          <div className='row d-flex justify-content-between'>
            <div className='col-xl-6 col-lg-8 col-sm-11 fw-normal '>
              <p className='fz-20px'>
                {intl.formatMessage({ id: 'PROJECT.SHORT_DESC' })}
              </p>
            </div>
            <div className='col-xl-3 col-lg-4 col-sm-5 fw-bolder d-flex position-relative'>
              <p className='fz-16px align-middle'> {intl.formatMessage({ id: 'PROJECT.EXPLORE_ALL' })}</p>
              <button className='project-button-radio position-absolute'>
                <a href='/projects'>
                  <div className='line-width'>
                    <i className='arrow right'></i>
                  </div>
                </a>
              </button>
            </div>
          </div>
        </div>

        <div className='products'>
          <div className='row d-flex justify-content-between'>
            {recordsProject.map((item, idx) =>
              <div key={idx} className='width-600 col-xxl-6'>
                <div className='col-xxl-12 position-relative shadow'>
                  <img src={item.thumbnail} alt={`${item.alias}-thumb`} className='image' />
                  <div className='products-overlay-img d-flex'>
                    <div className='d-flex flex-column padding-hover'>
                      <button className='button-hover text-white '>
                        {/* <img src={item.logo} alt={`${item.alias}-logo`} className=' left-img' /> */}
                      </button>
                      <div className='text-hover'>
                        <p>{item.type}</p>
                        <div className='line-width-hove'>
                          <h3>{item.name}</h3>
                        </div>
                      </div>
                      <div className='col-xxl-11 lh-lg padding-text-span'>
                        <span>
                          {item.shortDescription}
                        </span>
                      </div>
                      <div className='d-flex align-items-end'>
                        <div>
                          <a href={`/project-detail/${item.alias}-${item.id}.html`} className='fs-6 text-start text-white'>
                            {intl.formatMessage({ id: 'PROJECT.EXPLORE_FULL_PROJECT' })}
                          </a>
                        </div>
                        <div className='line-width-icon justify-content-end'>
                          <i className='arrow right-icon'></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='product-text'>
                  <p>{item.type}</p>
                  <span className='fw-bolder'>{item.name}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectContent

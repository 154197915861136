import React, { Fragment } from 'react';
import Marquee from "react-fast-marquee";
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import './banner.scss';

const servicesData = [
	{ serviceId: 1, serviceName: "E-Commerce" },
	{ serviceId: 2, serviceName: "Web Application" },
	{ serviceId: 3, serviceName: "Web Information" },
	{ serviceId: 4, serviceName: "Landing Page" },
	{ serviceId: 5, serviceName: "Mobile Application" },
	{ serviceId: 6, serviceName: "Game Application" },
	{ serviceId: 7, serviceName: "Logo Design" },
	{ serviceId: 8, serviceName: "Banner Design" },
	{ serviceId: 9, serviceName: "Template Design" },
	{ serviceId: 10, serviceName: "Event Design" },
]

const BannerWrapper = () => {
	const intl = useIntl(); 
	return <>
		<div className="banner-container">
			<video src={toAbsoluteUrl(`/media/videos/banner/bg-video-${Math.floor(Math.random() * 2) + 1}.mp4`)} autoPlay loop muted preload="none" />
			<h1>{intl.formatMessage({ id: "HOME.VIDEO.SLOGAN" })}</h1>
			<p>{intl.formatMessage({ id: "HOME.VIDEO.SLOGAN_DESC" })}</p>
			<div className="banner-btn">
        <a href='#kt_explore'>
          <p>{intl.formatMessage({ id: "HOME.EXPLOER_MORE" })}</p>
          <div className="arrow"/>
        </a>
			</div>
		</div>
		<Marquee className="banner-fields" gradient={false} >
			{
				servicesData.map(item => {
					return <Fragment key={item.serviceId}>
						<div className="field-items"><p>{item.serviceName}</p></div>
						<div className="field-divider">
							<img alt='' src={toAbsoluteUrl('/media/images/banner/dots-menu.png')}/>
						</div>
					</Fragment>
				})
			}
		</Marquee>
	</>
}

export { BannerWrapper };

